import React from "react";
import abhishekPic from "../../assets/img/Abhishek_pic.jpg";
import ajitPandeyPic from "../../assets/img/Ajit_pandey.jpg";
const Team = () => {
  return (
    <section id="team" className="team section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Leadership Team</h2>
        <p>
          The leadership team at Mobitra Technologies is a dynamic blend of
          technical innovation, strategic foresight, and proven industry
          experience. With a shared vision for delivering cutting-edge
          solutions, they bring decades of expertise from leading global
          organizations across diverse sectors, driving Mobitra's success in
          revolutionizing technology-driven systems.
        </p>
      </div>
      <div className="container">
        <div className="row gy-5 d-flex justify-content-center">
          <div
            className="col-lg-4 col-md-6 member"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="member-img">
              <img src={abhishekPic} className="img-fluid" alt="" />
              {/* <div className="social">
                <a href="#">
                  <i className="bi bi-twitter-x"></i>
                </a>
                <a href="#">
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="#">
                  <i className="bi bi-instagram"></i>
                </a>
                <a href="#">
                  <i className="bi bi-linkedin"></i>
                </a>
              </div> */}
            </div>
            <div className="member-info text-center">
              <h4>Mr. Abhishek Srivastava</h4>
              <span>(Founder & CEO)</span>
              <p>
                Abhishek has over 20 years of global IT product and services
                experience, having led transformative programs for companies
                like Wipro, Unilever, and Kimberly Clark. A graduate of IIT-ISM
                Dhanbad and HEC Paris, his career is defined by a commitment to
                innovation and customer-centric solutions. Under his leadership,
                Mobitra has launched impactful technologies like Vedum AI and
                fleet management systems.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container section-title mt-4" data-aos="fade-up">
        <h2>Tech. & Op. Leadership</h2>
        <div style={{ textAlign: "left" }}>
          <p>
            The technical and operational leadership team brings a wealth of
            expertise, honed through experiences at premier global
            organizations. Key achievements include:
          </p>
          <ul>
            <li>
              Financial & Payment Systems Expertise: Deep involvement in
              developing secure, scalable, and innovative solutions with leading
              organizations such as Worldpay, Pollinate, and 10x Banking,
              driving transformations in the fintech space.
            </li>
            <li>
              Healthcare & Research Contributions: Leveraged technology to
              enhance critical research processes for entities like Cancer
              Research UK, showcasing adaptability in addressing unique
              challenges.
            </li>
            <li>
              Media & Telecom Innovations: Delivered cutting-edge solutions for
              companies such as Goldman Sachs, Channel 4, and Sky TV,
              highlighting expertise across diverse industries.
            </li>
          </ul>
          <p>
            Together, this leadership team has created a robust foundation for
            Mobitra's success, combining a rich mix of operational precision,
            advanced technical capabilities, and a relentless focus on customer
            outcomes. Their efforts have been pivotal in positioning Mobitra as
            a leader in AI-powered legal research tools and IoT-based fleet
            management systems.
          </p>
        </div>
      </div>
      <div className="container section-title mt-4" data-aos="fade-up">
        <h2>Advisors</h2>
      </div>
      <div className="container">
        <div className="row gy-5 d-flex justify-content-center">
          <div
            className="col-lg-4 col-md-6 member"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="member-img">
              <img src={ajitPandeyPic} className="img-fluid" alt="" />
              {/* <div className="social">
                <a href="#">
                  <i className="bi bi-twitter-x"></i>
                </a>
                <a href="#">
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="#">
                  <i className="bi bi-instagram"></i>
                </a>
                <a href="#">
                  <i className="bi bi-linkedin"></i>
                </a>
              </div> */}
            </div>
            <div className="member-info text-center">
              <h4>Mr. Ajit Pandey</h4>
              <p>
                Ajit Pandey an IIT Dhanbad alumni, is an accomplished
                entrepreneur and technology strategist with over 24 years of
                expertise in building digital products and platforms digital. He
                has successfully co-founded and scaled multiple ventures,
                including Loan Street India, BidBerry, and Education 360,
                leveraging his technical and strategic acumen to solve
                real-world problems.
              </p>
              <p>
                As the CTO of Loan Street India, Ajit revolutionized the fintech
                space with an AI-driven loan marketplace that integrated with 35
                leading banks, automating 80% of processes and increasing loan
                conversion rates by 500%. With BidBerry, a logistics startup,
                Ajit developed an intelligent platform to streamline
                transportation by bidding for a given logistic need posted by a
                customer to move households within the city or intercity. His
                venture Education 360 addressing the gaps in personalized
                learning for students in tier 3 cities of India by providing
                them access to same level of resources available to more
                privileged students from metro cities.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
